import { Form, Col, Row, Modal } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { publish } from "../../services/mqttClient";
import { AuthContext } from "../../AuthProvider";
import '../../routes/tempcontrol.css';

const TempSettings = () => {

    const { esp, topics, updateUserEsp } = useContext(AuthContext);

    const [showHelp, setShowHelp] = useState(false);

    const handleCloseHelp = () => setShowHelp(false);

    const handleShowHelp = () => setShowHelp(true);

    async function updateAwayTemp(newValue) {
        publish({ topic: topics?.sensorTopic + "/AWAY", qos: 0, payload: newValue + "" });
    }

    async function updateMinTemp(newValue) {
        publish({ topic: topics?.sensorTopic + "/MIN", qos: 0, payload: newValue + "" });
    }

    async function updateIdealTemp(newValue) {
        publish({ topic: topics?.sensorTopic + "/IDEAL", qos: 0, payload: newValue + "" });
    }

    async function updateMaxTemp(newValue) {
        publish({ topic: topics?.sensorTopic + "/MAX", qos: 0, payload: newValue + "" });
    }

    const handleAwayTempInput = (event) => {
        const newValue = parseInt(event.target.value);
        if (newValue > -1 && newValue < 31
        ) {
            const newEsp = { ...esp };
            newEsp.awayTemp = newValue;
            updateAwayTemp(newValue);

            updateUserEsp(newEsp);
        }
    };

    const handleMinTempInput = (event) => {
        const newValue = parseInt(event.target.value);
        if (newValue > -1 && newValue < 31
        ) {
            const newEsp = { ...esp };
            newEsp.minTemp = newValue;
            updateMinTemp(newValue);

            if (newEsp.idealTemp < newValue) {
                newEsp.idealTemp = newValue;
                updateIdealTemp(newValue);
            }
            if (newEsp.maxTemp < newValue) {
                newEsp.maxTemp = newValue;
                updateMaxTemp(newValue);
            }
            updateUserEsp(newEsp);
        }
    };

    const handleIdealTempInput = (event) => {
        const newValue = parseInt(event.target.value);
        if (newValue > -1 && newValue < 31) {
            const newEsp = { ...esp };
            newEsp.idealTemp = newValue;
            updateIdealTemp(newValue);

            if (newEsp.minTemp > newValue) {
                newEsp.minTemp = newValue;
                updateMinTemp(newValue);
            }
            if (newEsp.maxTemp < newValue) {
                newEsp.maxTemp = newValue;
                updateMaxTemp(newValue);
            }
            updateUserEsp(newEsp);
        }
    };

    const handleMaxTempInput = (event) => {
        const newValue = parseInt(event.target.value);
        if (newValue > -1 && newValue < 31) {
            const newEsp = { ...esp };
            newEsp.maxTemp = newValue;
            updateMaxTemp(newValue);

            if (newEsp.minTemp > newValue) {
                newEsp.minTemp = newValue;
                updateMinTemp(newValue);
            }
            if (newEsp.idealTemp > newValue) {
                newEsp.idealTemp = newValue;
                updateIdealTemp(newValue);
            }
            updateUserEsp(newEsp);
        }
    };

    return (
        <div>
            <Form className="temp-form border-top mb-4">
                <div className="d-flex align-items-center mt-3">
                    <h5>Temperaturreglering</h5>
                    <i className="bi bi-info-circle ms-2 mb-2" onClick={handleShowHelp} style={{ cursor: 'pointer', color: '#007bff' }} />
                </div>
                <Row>
                    <Col sm={12} md={6} lg={3}>
                        <Form.Group controlId="awayTempRange">

                            <Form.Label>
                                Bortrest {esp?.awayTemp}°C
                            </Form.Label>

                            <Form.Control
                                type="number"
                                className="temp-number-input"
                                disabled={esp?.automationStatusHeating ? false : true}
                                min={0}
                                max={30}
                                placeholder="Ange minsta temperatur när du är bortrest"
                                value={esp?.awayTemp}
                                onChange={handleAwayTempInput}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <Form.Group controlId="minTempRange">

                            <Form.Label>
                                Min {esp?.minTemp}°C
                            </Form.Label>

                            <Form.Control
                                type="number"
                                className="temp-number-input"
                                disabled={esp?.automationStatusHeating ? false : true}
                                min={0}
                                max={30}
                                placeholder="Ange minsta temperatur"
                                value={esp?.minTemp}
                                onChange={handleMinTempInput}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <Form.Group controlId="idealTempRange">
                            <Form.Label>Ideal {esp?.idealTemp}°C</Form.Label>
                            <Form.Control
                                type="number"
                                className="temp-number-input"
                                disabled={esp?.automationStatusHeating ? false : true}
                                min={0}
                                max={30}
                                placeholder="Ange medeltemperatur"
                                value={esp?.idealTemp}
                                onChange={handleIdealTempInput}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <Form.Group controlId="maxTempRange">
                            <Form.Label>Max {esp?.maxTemp}°C</Form.Label>
                            <Form.Control
                                type="number"
                                className="temp-number-input"
                                disabled={esp?.automationStatusHeating ? false : true}
                                min={0}
                                max={30}
                                placeholder="Ange högsta temperatur"
                                value={esp?.maxTemp}
                                onChange={handleMaxTempInput}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Temperaturreglering</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Här ställer du in ditt önskade spann för inomhustemperatur. Inom spannet min-max tillåts inomhusvärmen variera under dygnet. Den högsta värmen används för att buffra värme i huset vid lågt elpris för att sedan låta det sjunka till minsta temperaturen under de dyraste timmarna. Borta anger den minsta temperatur som skall hållas för att huset inte skall påverkas negativt av fukt eller frysskador när du är bortrest.
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default TempSettings;


