
import logo from "../minicitytransparent.png"
import { NavLink, Outlet } from "react-router-dom";
import React, { useContext, useState } from 'react';
import { AuthContext } from "../AuthProvider";

export default function Menu() {

    const { displayName, logout } = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function from the context
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    return (
        <>
            <div id="sidebar" className="sidebar d-none d-md-block">
                <nav>
                    <NavLink to="/" className={() => ""}>
                        <img src={logo} className="App-logo" alt="logo" />
                    </NavLink>
                    <ul>
                        <li>
                            <NavLink to="/"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-thermometer" />
                                Värmestyrning
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/hotwater"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-moisture" />
                                Varmvatten
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/statistics"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-bar-chart-fill" />
                                Statistik
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/account"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-person-circle" />
                                {displayName}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={handleLogout} className={() => ""}>
                                <i className="bi bi-box-arrow-right" />
                                Logga ut
                            </NavLink>
                        </li>
                    </ul></nav>
            </div>
            <div id="detail">
                {/* Mobile hamburger button */}
                {/* TODO: Lägg till divar här för att styra placering av hamburgerknapp, men vet inte hur utan att sidebar-innehåll påverkas även i stora versionen */}
                <button className="menu-toggle d-md-none" onClick={toggleMenu}>
                    <i className="bi bi-list" /> {/* Hamburger icon */}
                </button>
                <Outlet />
            </div>

            {/* Mobile Menu (visible only on small screens) */}
            {menuOpen && (
                <div className="mobile-menu d-md-none">
                    <nav>
                        <ul>
                            <li>
                                <NavLink to="/" onClick={closeMenu}>
                                    <i className="bi bi-thermometer" />
                                    Värmestyrning
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/hotwater" onClick={closeMenu}>
                                    <i className="bi bi-moisture" />
                                    Varmvatten
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/statistics" onClick={closeMenu}>
                                    <i className="bi bi-bar-chart-fill" />
                                    Statistik
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/account" onClick={closeMenu}>
                                    <i className="bi bi-person-circle" />
                                    {displayName}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => { handleLogout(); closeMenu(); }}>
                                    <i className="bi bi-box-arrow-right" />
                                    Logga ut
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </>
    );
}