import React, { createContext, useState, useEffect } from "react";
import Parse from "parse";
import { useNavigate } from "react-router-dom";
import { getEspByUser } from "./db/Esp";
//import { getClient, subscribe, unsubscribe } from "./services/mqttClient";
import { getClient } from "./services/mqttClient";
import { updateEsp } from "./db/Esp";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayName, setDisplayName] = useState(null);
  const [topics, setTopics] = useState(null);
  const [esp, setEsp] = useState(null);
  const [espLoading, setEspLoading] = useState(true);
  const [client, setClient] = useState(null);
  //const [isSubed, setIsSub] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a user is already logged in
    const currentUser = Parse.User.current();
    if (currentUser) {
      setUser(currentUser);
      setDisplayName(currentUser.get("name"));
    } else {
      navigate("/login");
    }
    setLoading(false);
  }, [navigate]);

  // 1: Set topics with correct esp id from db & get temperatures from db or put default values if not in db
  useEffect(() => {
    async function topics() {
      if (user) {
        const esp = await getEspByUser(user);
        if (esp) {
          setTopics({
            termostatSwitchTopic:
              "cmnd/" + esp.topicName + "/ThermostatModeSet",
            sensorTopic: "tele/" + esp.topicName + "/SENSOR",
          });
          setEsp(esp);
        }
        setEspLoading(false);
      }
    }
    topics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

      // 2: Connect to mqtt server
      useEffect(() => {
        async function connect() {
            if (topics?.sensorTopic) {
                setClient(await getClient());
            }
        }

        connect();

        return () => {
            if (topics?.sensorTopic) {
                // unsubscribe({ topic: topics.sensorTopic, qos: 0 });
                // setIsSub(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topics]);

    // 3: Subscribe to topics and listen for messages
    useEffect(() => {
        if (client && topics) {
            // https://github.com/mqttjs/MQTT.js#event-connect
            client.on("connect", () => {
                console.log("on connect");
                // if (!isSubed) {
                //     subscribe({ topic: topics.sensorTopic, qos: 0 });
                //     setIsSub(true);
                // }
            });

            // https://github.com/mqttjs/MQTT.js#event-error
            client.on("error", (err) => {
                console.error("Connection error: ", err);
                client.end();
            });

            // https://github.com/mqttjs/MQTT.js#event-reconnect
            client.on("reconnect", () => {
                console.log("reconnected");
                //setConnectStatus("Reconnecting");
            });

            // // https://github.com/mqttjs/MQTT.js#event-message
            // client.on("message", (topic, message) => {
            //     if (topic === topics?.sensorTopic) {
            //         const messageObj = JSON.parse(message);
            //         const thermostatModeSet = messageObj?.Thermostat1?.ThermostatModeSet;
            //         if (thermostatModeSet !== null && thermostatModeSet !== undefined) {
            //             setAutomationStatus(thermostatModeSet === 1 ? "ON" : "OFF");
            //         }
            //     }
            // });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, topics]);

  const login = async (username, password) => {
    try {
      const loggedInUser = await Parse.User.logIn(username, password);
      setUser(loggedInUser);
      return loggedInUser;
    } catch (error) {
      console.error("Login failed", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await Parse.User.logOut();
      setUser(null);
      setDisplayName(null);
    } catch (error) {
      console.error("Logout failed", error);
      throw error;
    }
  };

  const updateUserEsp = async (newEsp) => {
    updateEsp(newEsp.id, newEsp);
    setEsp(newEsp);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        displayName,
        login,
        logout,
        loading,
        topics,
        esp,
        espLoading,
        updateUserEsp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
