import { Form, Modal } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { publish } from "../../services/mqttClient";
import { AuthContext } from "../../AuthProvider";
import '../../routes/tempcontrol.css';

const AutomationStatus = () => {

    const { esp, topics, updateUserEsp } = useContext(AuthContext);

    const [showHelp, setShowHelp] = useState(false);

    const handleCloseHelp = () => setShowHelp(false);

    const handleShowHelp = () => setShowHelp(true);

    // Skicka kommando för att slå på/av automation
    const toggleAutomation = () => {
        const newEsp = { ...esp };
        newEsp.automationStatusHeating = !esp.automationStatusHeating
        updateUserEsp(newEsp);
        publish({
            topic: topics.termostatSwitchTopic,
            qos: 0,
            payload: newEsp.automationStatusHeating ? "1" : "0",
        });
    };

    return (
        <div>
            <Form className="automation-form">
                <Form.Group controlId="automationStatusHeating" className="mb-4">
                    <div className="d-flex align-items-center ms-2">
                        <Form.Check
                            type="switch"
                            id="automation-status-switch"
                            label="Smart uppvärmning aktiverad"
                            checked={esp.automationStatusHeating}
                            onChange={toggleAutomation}
                            className="mb-0 d-flex align-items-center"
                        />
                        <i className="bi bi-info-circle ms-2" onClick={handleShowHelp} style={{ cursor: 'pointer', color: '#007bff' }} />
                    </div>
                </Form.Group>
            </Form>

            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Smart uppvärmning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Genom att slå av denna inställning inaktiveras den automatiska styrningen och värmepumpens egen styrning används istället.
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default AutomationStatus;


