import { Form, Modal } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import '../../routes/tempcontrol.css';

const Mode = () => {

    const { esp, updateUserEsp } = useContext(AuthContext);

    const [showHelp, setShowHelp] = useState(false);

    const handleCloseHelp = () => setShowHelp(false);

    const handleShowHelp = () => setShowHelp(true);

    const handleModeChange = (event) => {
        const newEsp = { ...esp };
        newEsp.mode = event.target.value;
        updateUserEsp(newEsp);
    }

    return (
        <div>
            <Form className="automation-form">
                <Form.Group className="border-top mb-4">
                    <div className="d-flex align-items-center mt-3">
                        <h5>Uppvärmningsläge</h5>
                        <i className="bi bi-info-circle ms-2 mb-2" onClick={handleShowHelp} style={{ cursor: 'pointer', color: '#007bff' }} />
                    </div><div>
                        <Form.Check

                            label="Ekonomisk"
                            name="economic"
                            type="radio"
                            id="economic"
                            value="ECONOMIC"
                            onChange={handleModeChange}
                            checked={esp?.mode === "ECONOMIC"}
                            disabled={esp?.automationStatusHeating ? false : true}
                        />
                        <Form.Check

                            label="Bekväm"
                            name="comfort"
                            type="radio"
                            id="comfort"
                            value="COMFORT"
                            onChange={handleModeChange}
                            checked={esp?.mode === "COMFORT"}
                            disabled={esp?.automationStatusHeating ? false : true}
                        />
                        <Form.Check

                            label="Lyx"
                            name="lux"
                            type="radio"
                            id="lux"
                            value="LUX"
                            onChange={handleModeChange}
                            checked={esp?.mode === "LUX"}
                            disabled={esp?.automationStatusHeating ? false : true}
                        />
                    </div>
                </Form.Group>
            </Form>
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Uppvärmningsläge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Välj hur jämn temperatur som skall hållas respektive hur mycket pengar du vill spara. Mer svängningar i värmen ger större möjlighet att spara pengar men kan upplevas som mer obekvämt.
                </Modal.Body>
            </Modal>
        </div >

    )
}

export default Mode;


