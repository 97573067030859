import mqtt from "mqtt";

let client;

const username = process.env.REACT_APP_MQTT_USERNAME;
const password = process.env.REACT_APP_MQTT_PASSWORD;
const url = process.env.REACT_APP_MQTT_BROKER_URL;
const clientId = "react_" + Math.random().toString(16).substring(2, 8);
const options = {
  clientId: clientId,
  username: username,
  password: password,
  clean: true,
  reconnectPeriod: 1000, // ms
  connectTimeout: 30 * 1000, // ms
};

async function createClient() {
  console.log("create mqtt client");
  client = mqtt.connect(url, options);
  return client;
}

async function getClient() {
  if (client !== undefined){
    return Promise.resolve(client);
  } else {
    return await createClient();
  }
}

// subscribe topic
// https://github.com/mqttjs/MQTT.js#mqttclientsubscribetopictopic-arraytopic-object-options-callback
async function subscribe(subscription) {
  // topic & QoS for MQTT subscribing
  const { topic, qos } = subscription;
  const client = await getClient();
  client.subscribe(topic, { qos }, (error) => {
    if (error) {
      console.log("Subscribe to topics error", error);
      return;
    }
    console.log(`Subscribe to topics: ${topic}`);
  });
}

// unsubscribe topic
// https://github.com/mqttjs/MQTT.js#mqttclientunsubscribetopictopic-array-options-callback
async function unsubscribe(subscription) {
  const { topic, qos } = subscription;
  const client = await getClient();
  client.unsubscribe(topic, { qos }, (error) => {
    if (error) {
      console.log("Unsubscribe error", error);
      return;
    }
    console.log(`unsubscribed topic: ${topic}`);
  });
}

// publish message
// https://github.com/mqttjs/MQTT.js#mqttclientpublishtopic-message-options-callback
async function publish(context) {
  const { topic, qos, payload } = context;
  const client = await getClient();
  client.publish(topic, payload, { qos }, (error) => {
    if (error) {
      console.log("Publish error: ", error);
    }
  });
  console.log(`Published, topic: ${topic}, qos: ${qos}, payload: ${payload}`);
}

export { getClient, subscribe, unsubscribe, publish };
