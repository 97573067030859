import { Collapse, Spinner } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../AuthProvider";
import './tempcontrol.css';
import AutomationStatus from "../components/tempcontrol/AutomationStatus";
import Mode from "../components/tempcontrol/Mode";
import TempSettings from "../components/tempcontrol/TempSettings";

const Tempcontrol = () => {

    const { esp, espLoading} = useContext(AuthContext);

    const [tOpen, setTOpen] = useState(true);

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <div className="d-flex justify-content-between align-items-center" onClick={() => setTOpen(!tOpen)}>
                    <div className="d-flex align-items-center">
                        {espLoading && (<Spinner animation="border" role="status"></Spinner>)}
                        {!espLoading && !esp && (<h5>Ingen enhet kopplad</h5>)}
                        {esp && (<h5 className="mb-0">{esp.automationStatusHeating ? (<i className="bi bi-building-fill-check text-success" />) : (<i className="bi bi-building-fill-slash text-muted" />)}&nbsp;&nbsp;Värmestyrning {esp.customerChosenName}</h5>)}
                    </div>
                    <i
                        className={tOpen ? "bi bi-caret-up" : "bi bi-caret-down"}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <Collapse in={tOpen}>
                    <div className="mt-3">
                        {!espLoading && !esp && <p>Du har ingen värmestyrningsenhet kopplad till ditt kundkonto.</p>}
                        {/* Värmestyrning av eller på */}
                        {esp && (
                            <div>
                                <AutomationStatus />
                                <Mode />
                                <TempSettings />
                            </div>)}
                    </div>
                </Collapse>
            </div>
        </div >

    )
}

export default Tempcontrol;


