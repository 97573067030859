import { Form, Modal, Row, Col, Button } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import './HotWaterTimes.css';

const HotWaterTimes = () => {
    const [showHelp, setShowHelp] = useState(false);
    const { esp, updateUserEsp } = useContext(AuthContext);

    const handleCloseHelp = () => {
        setShowHelp(false);
    }

    const [timeInput, setTimeInput] = useState(""); // Input value for time

    // Function to handle adding a time
    const handleAddTime = () => {
        if (!esp.hotWaterTimes) {
            esp.hotWaterTimes = [];
        }
        if (timeInput && !esp.hotWaterTimes.includes(timeInput)) {
            esp.hotWaterTimes = [...esp.hotWaterTimes, timeInput].sort(); // Add time to the list if not empty and not already in the array
            setTimeInput(""); // Reset input after adding
            updateUserEsp(esp);
        }
    };

    // Function to handle input changes
    const handleInputChange = (e) => {
        if (e.keyCode === 13) {
            handleAddTime();
        }
        setTimeInput(e.target.value);
    };

    // Function to remove a time from the list
    const handleRemoveTime = (timeToRemove) => {
        const newEsp = { ...esp, hotWaterTimes: esp.hotWaterTimes.filter((time) => time !== timeToRemove) };
        updateUserEsp(newEsp);
    };

    // Handle Enter key press to submit the time
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission
            handleAddTime(); // Trigger add time when Enter is pressed
        }
    };

    const isDisabled = (input) => {
        return input + (esp?.automationStatusWater ? "" : " disabled");
    }


    return (
        <>
            <div className="mt-3">
                <Form className="temp-form border-top mb-4">
                    <div className="d-flex align-items-center mt-3">
                        <h5>Tider för varmvatten</h5>
                        <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
                    </div>
                    <Row className="align-items-center mb-3">
                        <Col xs={3}>
                            <Form.Control
                                type="time"
                                value={timeInput}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyPress}
                                placeholder="Enter time"
                                disabled={esp?.automationStatusWater ? false : true}
                            />
                        </Col>
                        <Col xs={4}>
                            <Button onClick={handleAddTime} className="plus-btn" disabled={esp?.automationStatusWater ? false : true}>
                                <i className="bi bi-plus-lg"></i> {/* Bootstrap plus icon */}
                            </Button>
                        </Col>
                    </Row>

                    {/* Display the list of added times */}
                    <div className="times-list">
                        {esp.hotWaterTimes?.length > 0 ? (
                            <ul className="list-group">
                                {esp.hotWaterTimes.map((time, index) => (
                                    <li key={index} className={isDisabled("list-group-item d-flex justify-content-between align-items-center hot-water-item")}>
                                        <div>
                                            <i className="bi bi-clock" style={{ marginRight: '10px' }}></i>
                                            {time}
                                        </div>
                                        <Button className="minus-btn"
                                            variant="danger"
                                            onClick={() => handleRemoveTime(time)} // Remove time on click
                                            disabled={esp?.automationStatusWater ? false : true}
                                        >
                                            <i className="bi bi-dash-lg"></i> {/* Minus icon */}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>Inga angivna tider för varmvatten. Vattnet kommer värmas en gång per dygn när elen är som billigast.</p>
                        )}
                    </div>

                </Form>

            </div>
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tider för varmvatten</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Vilka tider behövs varmvatten i hushållet som mest? Anges ingen tid värms vattnet en gång per dygn när det är som billigast, vanligtvis under natten. Ju färre tider som anges desto större möjlighet att optimera elpriset.
                </Modal.Body>
            </Modal>
        </>

    )
}

export default HotWaterTimes;


